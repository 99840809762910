<template>
	<div class="app-container">
		<el-card class="elCard">
			<div>
				<i class="el-icon-search"></i>
				<span>查询条件</span>
				<el-button style="float: right; margin-left: 5px;" @click="resetForm('searchForm')" size="small">清空</el-button>
				<el-button style="float: right" @click="searchTable()" type="primary" size="small" icon="el-icon-search">查询</el-button>
			</div>
			<div style="margin-top: 15px">
				<el-form :inline="true" :model="searchForm" ref="searchForm" size="small" label-width="100px">
					<el-form-item label="阶段名称：" prop="name">
						<el-input v-model="searchForm.name" placeholder="请输入阶段名称"></el-input>
					</el-form-item>
					<el-form-item label="赛制名称：" prop="stageId">
						<el-select v-model="searchForm.stageId" clearable placeholder="请选择赛制" autocomplete="off" style="max-width: 200px;">
							<el-option v-for="item in formatArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="是否启用：" prop="status">
						<el-select v-model="searchForm.status" clearable placeholder="请选择" style="max-width: 200px;">
						    <el-option v-for="item in statusArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
		<el-row>
			<el-button type="primary" size="medium" icon="el-icon-circle-plus-outline" @click="append()">新增</el-button>
			<el-popover placement="bottom" width="150" v-model="visible" style="float: right;">
				<el-checkbox-group v-model="checkList" @change="checkedChange">
				    <el-checkbox v-for="item in checkArr" :label="item.val">{{item.label}}</el-checkbox>
				</el-checkbox-group>
				<el-button type="primary" size="medium" icon="el-icon-s-grid" circle slot="reference"></el-button>
			</el-popover>
			<el-button style="float: right; margin-right: 5px;" size="medium" type="primary" icon="el-icon-refresh-left" circle @click="page = 1;getList()"></el-button>
		</el-row>
		<el-card>
			<el-table :data="tableData" :stripe="true" border v-loading="listLoading" style="width: 100%" @selection-change="handleSelectionChange">
				<!-- <el-table-column type="selection" width="55"></el-table-column> -->
				<el-table-column v-for="item in checkArr" v-if="item.dis" :prop="item.val" :label="item.label" min-width="110">
					<template slot-scope="scope">
						<span v-if="item.val == 'status'">{{scope.row.status == 0 ? '禁用' : '启用'}}</span>
						<span v-else>{{scope.row[item.val]}}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="140" fixed="right">
					<template slot-scope="scope">
						<el-button class="marginRight10" type="primary" size="mini" icon="el-icon-edit" @click="editTable(scope.$index, scope.row)">修改</el-button>
						<!-- <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteTable(scope.$index, scope.row)">删除</el-button> -->
						<el-switch v-model="scope.row.status" @change="switChange($event, scope.row)" active-color="#409eff" inactive-color="#ff4949" active-text="启用" inactive-text="禁用" :active-value="1" :inactive-value="0"></el-switch>
					</template>
				 </el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-pagination @current-change="handleCurrentChange" :current-page="page" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</el-card>
		<el-dialog width="55%" :title="dialogTitle" :visible.sync="dialogFormVisible">
		  <el-form :inline="true" :model="form" :rules="rules" ref="ruleForm" :label-width="formLabelWidth">
		    <el-form-item label="阶段名称：" prop="name">
		      <el-input v-model="form.name" placeholder="请输入阶段名称" autocomplete="off"></el-input>
		    </el-form-item>
		    <el-form-item label="赛制名称：" prop="stageName">
				<el-select v-model="form.stageName" clearable placeholder="请选择赛制" autocomplete="off" style="max-width: 202px;">
					<el-option v-for="item in formatArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
		    </el-form-item>
		    <el-form-item label="晋级人数：" prop="num">
		      <el-input v-model="form.num" placeholder="请输入晋级人数" autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" @blur="form.num = $event.target.value"></el-input>
		    </el-form-item>
		    <el-form-item label="执行顺序：" prop="sort">
		      <el-input v-model="form.sort" placeholder="请输入执行顺序" autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" @blur="form.sort = $event.target.value"></el-input>
		    </el-form-item>
		    <el-form-item label="备注：">
			  <el-input type="textarea" v-model="form.remark" placeholder="请输入备注" autocomplete="off"></el-input>
		    </el-form-item>
		    <el-form-item class="elItemBlock" label="套题：" prop="weight">
				<el-button type="success" size="mini" @click="againBtn">选择套题</el-button>
				<div class="proBox">
					<div v-for="(item, index) in form.weight" @click="infoTable(item.examId)">{{item.name ? item.name : item.paperName}}<i class="el-icon-error" @click.stop="deleteWeight(index)"></i><span>{{item.questionNum}}题</span></div>
				</div>
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button @click="dialogFormVisible = false">取 消</el-button>
		    <el-button type="primary" @click="dialogConfirm('ruleForm')">确 定</el-button>
		  </div>
		</el-dialog>
		<el-dialog title="选择套题" :fullscreen="true" :visible.sync="ratioVisible">
			<el-card style="margin-bottom: 20px;">
				<div>
					<i class="el-icon-search"></i>
					<span>查询条件</span>
					<el-button style="float: right; margin-left: 5px;" @click="resetForm('searchForm2')" size="small">清空</el-button>
					<el-button style="float: right" type="primary" size="small" icon="el-icon-search" @click="searchname">查询</el-button>
				</div>
				<div style="margin-top: 15px">
					<el-form :inline="true" :model="searchForm2" ref="searchForm2" size="small" label-width="100px">
						<el-form-item label="套题名称：" prop="name">
							<el-input v-model="searchForm2.name" placeholder="请输入套题名称"></el-input>
						</el-form-item>
					</el-form>
				</div>
			</el-card>
			<el-table :data="tableDatas" ref="multipleTable" :stripe="true" border v-loading="ratioLoading" style="width: 100%" @selection-change="handleSelectionChanges">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column v-for="item in checkArrs" v-if="item.dis" :prop="item.val" :label="item.label" min-width="110">
					<template slot-scope="scope">
						<span v-if="item.val == 'retake'">{{scope.row.retake == 1 ? '允许' : '不允许'}}</span>
						<span v-else-if="item.val == 'isCreate'">{{scope.row.isCreate == 0 ? '未生成' : '已生成'}}</span>
						<span v-else-if="item.val == 'status'">{{scope.row.status == 0 ? '禁用' : '启用'}}</span>
						<span v-else-if="item.val == 'examType'">{{scope.row.examType == 1 ? '手动组题' : '随机组题'}}</span>
						<span v-else>{{scope.row[item.val]}}</span>
					</template>
				</el-table-column>
				<el-table-column label="套题信息" min-width="120" fixed="right">
					<template slot-scope="scope">
						<el-button type="warning" size="mini" icon="el-icon-document" @click="infoTable(scope.row.id)">套题信息</el-button>
					</template>
				 </el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-pagination @current-change="handleCurrentChanges" :page-size="pageSize" :current-page="pages" layout="total, prev, pager, next, jumper" :total="totals"></el-pagination>
			<div slot="footer" class="dialog-footer">
				<el-button @click="ratioVisible = false">取 消</el-button>
				<el-button type="primary" @click="ratioConfirm">添 加</el-button>
			</div>
		</el-dialog>
		<el-dialog title="套题信息" :fullscreen="true" :visible.sync="quesVisible">
			<el-card>
				<div style="margin-bottom: 15px;">
					<i class="el-icon-search"></i>
					<span>查询条件</span>
					<el-button style="float: right;" @click="searchQuesTable()" type="primary" size="small" icon="el-icon-search">查询</el-button>
				</div>
				<el-form class="quesForm" :inline="true" :model="quesForm" size="small" label-width="100px">
					<el-form-item label="题目：">
						<el-input v-model="quesForm.title" placeholder="请输入关键字"></el-input>
					</el-form-item>
				</el-form>
			</el-card>
			<el-card style="margin-top: 15px;">
			<ul class="quesUl" v-loading="quesLoading">
				<li v-for="item in quesArr">
					<h2 :class="item.typeName == '单选题' ? 'colA' : 'colB'">{{item.typeName}}</h2>
					<h3>{{item.title}}</h3>
					<h4><i class="el-icon-view"></i> 答案：{{item.answer}} <span>难度：{{item.difficultLevel == 1 ? '易' : '难'}}</span></h4>
				</li>
			</ul>
			<!-- 分页 -->
			<el-pagination @current-change="quesChange" :current-page="paget" layout="total, prev, pager, next, jumper" :total="quesTotal"></el-pagination>
			</el-card>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				ratioVisible: false,
				ratioLoading: false,
				quesVisible: false,//套题信息-弹窗
				quesLoading: false,//套题信息loading
				listLoading: false,
				visible: false,
				dialogTitle: '修改数据',
				page: 1,
				pages: 1,
				paget: 1,
				pageSize: 20,
				total: 0,//分页总条数
				totals: 0,
				searchForm: {
					name: '',
					stageId: '',
					status: 1
				},//条件查询form
				searchForm2: {
					name: ''
				},
				statusArr: [{
					value: 0,
					label: "禁用"
				}, {
					value: 1,
					label: "启用"
				}],//是否启用
				tableData: [],//表格
				multipleSelection: [],//表格选中数组
				multipleSelections: [],//表格选中数组
				checkArr: [
					{label: "阶段名称", val: "name", dis: true}, 
					{label: "赛制名称", val: "stageName", dis: true}, 
					{label: "原始晋级人数", val: "stageNum", dis: true}, 
					{label: "晋级人数", val: "num", dis: true},
					{label: "执行顺序", val: "sort", dis: true},
					{label: "启用状态", val: "status", dis: true},
					{label: "备注", val: "remark", dis: true}
				],
				checkList: ["name", "stageName", "stageNum", "num", "sort", "status", "remark"],
				dialogFormVisible: false,//修改、新增弹窗
				formLabelWidth: '120px',//修改、新增弹窗-label宽度
				form: {
					name: '',
					stageName: '',
					num: '',
					sort: '',
					remark: '',
					weight: []
				},//修改、新增弹窗-表单内容
				rules: {
					name: [{ required: true, message: '请输入阶段名称', trigger: 'blur' }],
					stageName: [{ required: true, message: '请输入赛制名称', trigger: 'blur' }],
					num: [{ required: true, message: '请输入晋级人数', trigger: 'blur' }],
					sort: [{ required: true, message: '请输入执行顺序', trigger: 'blur' }],
					weight: [{ required: true, message: '请选择套题', trigger: 'blur' }]
				},
				formatArr: [],//赛制选择
				tableDatas: [],//套题表格
				checkArrs: [{
					label: "套题名称", val: "name", dis: true,
				}, {
					label: "题目数量", val: "questionNum", dis: true,
				}, {
					label: "是否启用", val: "status", dis: true,
				}, {
					label: "是否重做", val: "retake", dis: true,
				}, {
					label: "组题类型", val: "examType", dis: true,
				}, {
					label: "是否组题", val: "isCreate", dis: true,
				}],//套题表格
				quesForm: {
					title: ''
				},//套题信息顶部
				infoId: '',//套题id-套题信息内使用
				quesArr: [],//套题信息列表
				quesTotal: 0,//套题信息-分页总数
			}
		},
		components: {
			
		},
		created(){
			this.getList();
			this.$comjs.ajax.getAjax('/fa/exam/game/findStageList', {}, this, res => {
				this.formatArr = res.list;
			});
		},
		methods:{
			getList() {
				this.listLoading = true;
				// 查询内容转数组
				let obj = this.searchForm;
				let arr = Object.keys(obj);
				let arrs = [];
				arr.forEach(item => {
					let o = {};
					o.col = item;
					o.val = obj[item];
					if(item == "name") {
						o.type = "like";
					} else if(item == "stageId") {
						o.type = "=S";
					} else {
						o.type = "=";
					}
					arrs.push(o);
				})
				
				let data = {
					entity: 'ViewFaExamTypeStageRef',
					page: this.page,
					pageSize: 10,
					filter: JSON.stringify(arrs),
					sidx: 'sort',
					sord: 'asc'
				}
				this.$comjs.ajax.getAjax('/jqGrid/faCommonlist', data, this, res => {
					this.tableData = res.rows;
					this.total = res.total;
					this.listLoading = false;
				});
			},
			// 套题列表
			getAjax() {
				this.ratioLoading = true;
				let data = {
					pageIndex: this.pages,
					pageSize: this.pageSize,
					name: this.searchForm2.name,
					type: '',
					examType: '',
					retake: '',
					examinationPerson: '',
					isCreate: '1',
					status: '1'
				}
				this.$comjs.ajax.getAjax('/fa/exam/paper/grid/list', data, this, res => {
					this.tableDatas = res.res;
					// const arr = this.tableDatas.filter((item => item.status === 1 && item.isCreate === 1));
					// this.tableDatas = arr;
					this.totals = res.total;
					this.$nextTick(() => {
						if (this.form.weight.length > 0) {
							this.tableDatas.forEach(item => {
								this.form.weight.forEach(row => {
									if(item.id == row.examId) {
										this.$refs.multipleTable.toggleRowSelection(item);
									}
								});
							})
						} else {
							this.$refs.multipleTable.clearSelection();
						}
					})
					this.ratioLoading = false;
				});
			},
			// 搜索
			searchTable() {
				this.page = 1;
				this.getList();
			},
			searchname() {
				this.pages = 1;
				this.getAjax();
			},
			// 重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.page = 1;
				this.getList();
			},
			// 新增
			append() {
				this.dialogFormVisible = true;
				this.dialogTitle = "新增数据";
				this.form = {
					id: '',
					name: '',
					stageName: '',
					num: '',
					sort: '',
					remark: '',
					weight: []
				}
			},
			// 多选框
			checkedChange(value) {
				let arr = this.checkArr;
				arr.forEach(item => {
					item.dis = false;
				})
				value.forEach(item => {
					let index = arr.findIndex(d => d.val === item);
					arr[index].dis = true;
				})
				this.checkArr = arr;
			},
			// 表格选中
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			handleSelectionChanges(val) {
				this.multipleSelections = val;
			},
			// 分页
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			handleCurrentChanges(val) {
				this.pages = val;
				this.getAjax();
			},
			// 修改
			editTable(index, row) {
				this.dialogFormVisible = true;
				this.dialogTitle = "修改数据";
				let data = {
					id: row.id
				}
				this.$comjs.ajax.getAjax('/fa/exam/game/typeStageDetail', data, this, res => {
					this.form = {
						id: res.data.id,
						name: res.data.name,
						stageName: res.data.stageId,
						num: res.data.num,
						sort: res.data.sort,
						remark: res.data.remark,
						weight: res.data.paperList
					}
				});
			},
			// 修改-选择套题
			againBtn() {
				this.ratioVisible = true;
				// this.multipleSelections = this.form.weight;
				// console.log(this.multipleSelections)
				this.getAjax();
			},
			// 修改/新增-确定
			dialogConfirm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let str = "";
						this.form.weight.forEach(item => {
							if(str == "") {
								str = item.examId;
							} else {
								str = str + ',' + item.examId
							}
						})
						let data = {
							id: this.form.id ? this.form.id : "",
							name: this.form.name,
							stageId: this.form.stageName,
							num: this.form.num,
							sort: this.form.sort,
							remark: this.form.remark,
							examIds: str
						}
						this.$confirm('是否提交数据', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$comjs.ajax.postAjax('/fa/exam/game/editTypeStage', data, this, res => {
								this.dialogFormVisible = false;
								this.$refs[formName].resetFields();
								this.$message({
									message: '保存成功！',
									type: 'success',
									duration: 1000
								});
								this.getList();
							});
						}).catch(() => {
							
						});
					}
				});
			},
			// 删除
			deleteTable(index, row) {
				// let data = {
				// 	entity: 'FaExamTypeStageRef',
				// 	id: row.id
				// }
				// this.$comjs.ajax.postAjax('/jqGrid/delete', data, this, res => {
				// 	this.tableData.splice(index, 1);
				// });
			},
			// 表格-是否启用
			switChange(val, row) {
				let data = {
					id: row.id,
					status: val,
					entity: 'FaExamTypeStageRef'
				}
				this.$comjs.ajax.postAjax('/jqGrid/changeStatus', data, this, res => {
					this.$message({
						message: res.message,
						type: 'success'
					});
					this.getList();
				});
			},
			// 套题确认添加
			ratioConfirm() {
				console.log(this.multipleSelections)
				this.multipleSelections.forEach(item => {
					// 判断是否重复选择
					let index = this.form.weight.findIndex(d => d.examId === item.id);
					if(index == -1) {
						item.examId = item.id;
						this.form.weight.push(item);
					}
				})
				console.log(this.form.weight)
				this.multipleSelections = [];
				this.ratioVisible = false;
			},
			// 删除套题
			deleteWeight(index) {
				this.form.weight.splice(index, 1);
			},
			// 获取试题信息
			getInfo(page) {
				this.quesLoading = true;
				let data = {
					examId: this.infoId,
					title: this.quesForm.title,
					pageIndex: this.paget,
					pageSize: 10
				}
				this.$comjs.ajax.getAjax('/fa/exam/paper/detail/list', data, this, res => {
					this.quesArr = res.list;
					this.quesTotal = res.total;
					this.quesLoading = false;
				});
			},
			// 套题信息
			infoTable(row) {
				this.quesForm.title = "";
				this.quesVisible = true;
				this.infoId = row;
				this.paget = 1;
				this.getInfo();
			},
			// 套题信息-查询
			searchQuesTable() {
				this.paget = 1;
				this.getInfo();
			},
			// 套题信息-分页
			quesChange(val) {
				this.paget = val;
				this.getInfo();
			},
		},
		computed:{
			
		}
	}
</script>

<style lang="scss" scoped>
	.app-container {
		padding: 20px;
	}
	.el-row {
		padding: 20px 0;
	}
	.el-pagination {
		margin-top: 15px;
		text-align: left;
	}
	.proBox {
		// min-height: 20px;
		padding: 5px 5px 0;
		border: 1px solid #DCDFE6;
	}
	.proBox div {
		display: inline-block;
		min-width: 100px;
		margin-right: 5px;
		margin-bottom: 5px;
		padding-left: 5px;
		border-radius: 2px;
		border: 1px solid #DCDFE6;
		background-color: #FAFAFA;
		vertical-align: top;
		cursor: pointer;
	}
	.proBox div span {
		float: right;
		margin-left: 10px;
	}
	.proBox div i {
		float: right;
		margin-left: 5px;
		color: #f55;
		cursor: pointer;
	}
	.quesUl {
		margin: 0;
		padding: 0;
	}
	.quesUl li {
		margin: 0 0 10px;
		padding: 10px;
		border: 1px solid #e7eaec;
		border-radius: 2px;
		background: #FAFAFB;
		line-height: 20px;
		list-style: none;
		cursor: pointer;
	}
	.quesUl li:hover {
		background-color: #fff;
	}
	.quesUl li h2 {
		margin: 0 0 5px;
		font-weight: 400;
		font-size: 14px;
	}
	.colA {
		color: #409EFF;
	}
	.colB {
		color: #f8ac59;
	}
	.quesUl li h3 {
		margin: 0;
		font-weight: 400;
		font-size: 14px;
	}
	.quesUl li h4 {
		margin: 0;
		font-weight: 400;
		font-size: 12px;
	}
	.quesUl li h4 span {
		float: right;
	}
</style>
<style type="text/css">
	.app-container .elItemBlock {
		display: block;
	}
	.elItemBlock .el-form-item__content {
		width: calc(100% - 125px);
	}
</style>

